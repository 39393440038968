import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import Select from "react-select";

import { WORKFLOWS } from "services";
import { BasePrimaryColor } from "style-dictionary-dist/colors";

let HASMORE_FILTER = false;
let OFFSET_FILTER = 0;

const customSelectStyle = {
  option: (styles) => {
    return {
      ...styles,
      fontSize: "12px",
    };
  },
  placeholder: (provided, state) => {
    return { ...provided, fontSize: '0.8rem' };
  },
  singleValue: (provided, state) => {
    return { ...provided, fontSize: "12px" };
  },
  container: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      boxShadow: "none",
    };
  },
  control: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      boxShadow: "none",
      ":hover": {
        ...styles[":hover"],
        borderColor: BasePrimaryColor,
        boxShadow: "none",
      },
      borderColor: isFocused ? BasePrimaryColor : "#d7d7d7",
    };
  },
};

const WorkflowFilter = ({ onChangeFilter, value }) => {
  const [isLoading, setLoading] = useState(false);
  const [workflowsFilterParam, setWorkflowsFilterParam] = useState({
    offset: OFFSET_FILTER,
    limit: 20,
  });
  const [allOptions, setAllOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [keyword, setKeyword] = useState(undefined);

  const handleSearchWorkflow = (value) => {
    setWorkflowsFilterParam({
      ...workflowsFilterParam,
      q: value,
      offset: OFFSET_FILTER,
    });
  }
  const debounceFn = useCallback(debounce(handleSearchWorkflow, 1000), []);

  const getWorkflowFilter = async (params) => {
    try {
      setLoading(true);
      const { data } = await WORKFLOWS.getListWorkflows(params);
      if (data?.success && data?.data?.data?.length > 0) {
        setAllOptions((curr) => [
          ...curr,
          ...data?.data?.data?.map((x) => ({ value: x.id, label: x.name })),
        ]);
        setOptions((curr) => [
          ...curr,
          ...data?.data?.data?.map((x) => ({ value: x.id, label: x.name })),
        ]);
        setCurrentPage(data?.data?.current_page);
        setLastPage(data?.data?.last_page);

        if (data?.data?.current_page < data?.data?.last_page) {
          HASMORE_FILTER = true;
          OFFSET_FILTER = data?.data?.current_page * data?.data?.per_page;
        } else {
          HASMORE_FILTER = false;
          OFFSET_FILTER = 0;
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onWorkflowFilterReachBottom = () => {
    if (HASMORE_FILTER) {
      const params = {
        ...workflowsFilterParam,
        offset: OFFSET_FILTER,
      };
      setWorkflowsFilterParam(params);
    }
  };

  const onSearchWorkflowFilter = (value, actionMeta) => {
    if (actionMeta?.action === "input-change") {
      if (currentPage === lastPage) {
        const temp = allOptions.filter((x) =>
          x.label.toLowerCase().includes(value.toLowerCase())
        );
        setOptions(temp);
      } else {
        setKeyword(value);
        setAllOptions([]);
        setOptions([]);
        HASMORE_FILTER = false;
        OFFSET_FILTER = 0;

        debounceFn(value);
      }
    }
    if (actionMeta?.action === "menu-close") {
      if (keyword) {
        onCloseFilter();
      } else {
        setOptions(allOptions);
      }
    }
  };

  const onCloseFilter = () => {
    setKeyword(undefined);
    setAllOptions([]);
    setOptions([]);
    HASMORE_FILTER = false;
    OFFSET_FILTER = 0;

    setWorkflowsFilterParam({
      ...workflowsFilterParam,
      q: undefined,
      offset: OFFSET_FILTER,
    });
  };

  useEffect(() => {
    getWorkflowFilter(workflowsFilterParam);
  }, [workflowsFilterParam]);

  return (
    <Select
      placeholder="Workflow"
      value={value}
      closeMenuOnScroll={false}
      isSearchable={true}
      isClearable={true}
      options={options}
      isLoading={isLoading}
      styles={customSelectStyle}
      onMenuScrollToBottom={onWorkflowFilterReachBottom}
      onInputChange={onSearchWorkflowFilter}
      onChange={onChangeFilter}
    />
  );
};

export default WorkflowFilter;
